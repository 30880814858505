import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { FirestoreService } from './services/firestore.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {

  @HostBinding('class.loading') loading = true;

  // mock = [
  //   // {
  //   //   "id": "ElY00MCdyIZIu8VGES5lkWeYjhU2",
  //   //   "deleted": false,
  //   //   "role": "internal",
  //   //   "deletedAt": null,
  //   //   "createdAt": {
  //   //     "seconds": 1594334206,
  //   //     "nanoseconds": 960000000
  //   //   },
  //   //   "name": "Amanda",
  //   //   "email": "amanda.oliveira@umanni.com.br",
  //   //   "updatedAt": null,
  //   //   "photoUrl": "https://s3.amazonaws.com/umanni-email/personas/105.png",
  //   //   "active": true
  //   // },
  //   // {
  //   //   "deletedAt": null,
  //   //   "email": "filipe@umanni.com.br",
  //   //   "createdAt": {
  //   //     "seconds": 1594334206,
  //   //     "nanoseconds": 960000000
  //   //   },
  //   //   "updatedAt": null,
  //   //   "role": "internal",
  //   //   "active": true,
  //   //   "photoUrl": "https://s3.amazonaws.com/umanni-email/personas/105.png",
  //   //   "name": "Filipe veloso",
  //   //   "deleted": false,
  //   //   "id": "LfRMMuf5McPJrDkAz4QMVQmxvtU2"
  //   // },
  //   // {
  //   //   "updatedAt": null,
  //   //   "createdAt": {
  //   //     "seconds": 1594334206,
  //   //     "nanoseconds": 960000000
  //   //   },
  //   //   "deleted": false,
  //   //   "photoUrl": "https://s3.amazonaws.com/umanni-email/personas/105.png",
  //   //   "role": "internal",
  //   //   "deletedAt": null,
  //   //   "active": true,
  //   //   "email": "regiane.borges@umanni.com.br",
  //   //   "id": "6RJFweR2VMTkpXRfZkphxsz51gK2",
  //   //   "name": "Regiane Borges"
  //   // },
  //   // {
  //   //   "deleted": false,
  //   //   "name": "Suporte",
  //   //   "role": "internal",
  //   //   "createdAt": {
  //   //     "seconds": 1594334206,
  //   //     "nanoseconds": 960000000
  //   //   },
  //   //   "photoUrl": "https://s3.amazonaws.com/umanni-email/personas/105.png",
  //   //   "updatedAt": null,
  //   //   "deletedAt": null,
  //   //   "email": "suporte@umanni.com.br",
  //   //   "id": "wBUJDvzi3fVRoRjCStZLmwUaQLA2",
  //   //   "active": true
  //   // },
  //   // {
  //   //   "active": true,
  //   //   "name": "Wander Gmail",
  //   //   "email": "wander.cronos@gmail.com",
  //   //   "updatedAt": {
  //   //     "seconds": 1597767066,
  //   //     "nanoseconds": 715257000
  //   //   },
  //   //   "deleted": false,
  //   //   "role": "external",
  //   //   "photoUrl": "https://firebasestorage.googleapis.com/v0/b/umanni-feedback.appspot.com/o/users%2F8ghyqAp5QmhQwjUmUkv2GQnPn0t2?alt=media&token=d6b47138-d626-473f-8da1-28559fbddb63",
  //   //   "createdAt": {
  //   //     "seconds": 1596569963,
  //   //     "nanoseconds": 383877000
  //   //   },
  //   //   "id": "8ghyqAp5QmhQwjUmUkv2GQnPn0t2"
  //   // },
  //   // {
  //   //   "id": "gRAPJffwv2c1QXgyJxnZQwOqwhO2",
  //   //   "deleted": false,
  //   //   "updatedAt": {
  //   //     "seconds": 1598387515,
  //   //     "nanoseconds": 899000000
  //   //   },
  //   //   "active": true,
  //   //   "name": "Wander Rodrigues",
  //   //   "role": "admin",
  //   //   "deletedAt": null,
  //   //   "email": "wander.rodrigues@umanni.com.br",
  //   //   "createdAt": {
  //   //     "seconds": 1594334206,
  //   //     "nanoseconds": 960000000
  //   //   },
  //   //   "photoUrl": "https://firebasestorage.googleapis.com/v0/b/umanni-feedback.appspot.com/o/users%2FxWDnO3qpGzXavSbnruZnEP5KgDk2?alt=media&token=ec77ac6d-0951-483f-8038-210e555322af"
  //   // }
  // ]
  // organization = {
  //   "platforms": {
  //     "android": {
  //       "currentVersion": "3.0.0"
  //     },
  //     "web": {
  //       "currentVersion": "3.0.0"
  //     },
  //     "ios": {
  //       "currentVersion": "3.0.0"
  //     }
  //   },
  //   "id": "ambev-insights",
  //   "modules": {
  //     "messages": {
  //       "settings": {
  //         "moderation": false
  //       },
  //       "active": true
  //     },
  //     "events": {
  //       "settings": {},
  //       "active": true
  //     },
  //     "moods": {
  //       "active": true,
  //       "settings": {
  //         "blurOffensiveMaterial": true
  //       }
  //     },
  //     "surveys": {
  //       "active": true,
  //       "settings": {
  //         "multiple": true
  //       }
  //     },
  //     "tickets": {
  //       "active": true,
  //       "settings": {}
  //     },
  //     "feedbacks": {
  //       "active": true,
  //       "settings": {
  //         "anonymousFeedbacks": true,
  //         "enableModerate": false,
  //         "enableGroupFeedback": true,
  //         "enableSanitization": true,
  //         "skillHasContexts": false
  //       }
  //     },
  //     "notifications": {
  //       "active": true,
  //       "settings": {}
  //     }
  //   },
  //   "name": "Ambev",
  //   "logoUrl": null,
  //   "settings": {
  //     "users": {
  //       "notifications": {
  //         "engagement": {
  //           "neverLoggedIn": {
  //             "message": "string",
  //             "startAt": 5,
  //             "title": "string",
  //             "active": true
  //           },
  //           "daysWithoutLoggingIn30": {
  //             "title": "string",
  //             "message": "string",
  //             "active": true
  //           },
  //           "daysWithoutLoggingIn15": {
  //             "active": true,
  //             "message": "string",
  //             "title": "string"
  //           },
  //           "daysWithoutLoggingIn7": {
  //             "active": true,
  //             "message": "string",
  //             "title": "string"
  //           }
  //         }
  //       },
  //       "emails": {
  //         "newUsers": {
  //           "iosStatus": false,
  //           "webUrl": "string",
  //           "from": "string",
  //           "androidStatus": false,
  //           "subject": "string",
  //           "message": "string",
  //           "androidUrl": "string",
  //           "imageUrl": null,
  //           "iosUrl": "string",
  //           "fromName": "string"
  //         }
  //       }
  //     },
  //     "apparence": {
  //       "theme": "umanni-feedback",
  //       "colors": {
  //         "primary": "#bf370e",
  //         "accent": "#",
  //         "warn": "#"
  //       }
  //     }
  //   }
  // }

  constructor(private router: Router, private firestore: FirestoreService) {
    this.loading = false;
    // const USERS = [
    //   {
    //     "id": "gRAPJffwv2c1QXgyJxnZQwOqwhO2",
    //     "deleted": false,
    //     "updatedAt": {
    //       "seconds": 1598387515,
    //       "nanoseconds": 899000000
    //     },
    //     "active": true,
    //     "name": "Wander Rodrigues",
    //     "role": "admin",
    //     "deletedAt": null,
    //     "email": "wander.rodrigues@umanni.com.br",
    //     "createdAt": {
    //       "seconds": 1594334206,
    //       "nanoseconds": 960000000
    //     },
    //     "photoUrl": "https://firebasestorage.googleapis.com/v0/b/umanni-feedback.appspot.com/o/users%2FxWDnO3qpGzXavSbnruZnEP5KgDk2?alt=media&token=ec77ac6d-0951-483f-8038-210e555322af"
    //   }
    // //   {
    // //     "id": "LfRMMuf5McPJrDkAz4QMVQmxvtU2",
    // //     "createdAt": new Date(),
    // //     "updatedAt": null,
    // //     "deletedAt": null,
    // //     "deleted": false,
    // //     "name": "Filipe veloso",
    // //     "email": "filipe@umanni.com.br",
    // //     "role": "internal",
    // //     "photoUrl": "https://s3.amazonaws.com/umanni-email/personas/105.png",
    // //     "active": true
    // //   },
    // //   {
    // //     "id": "wBUJDvzi3fVRoRjCStZLmwUaQLA2",
    // //     "createdAt": new Date(),
    // //     "updatedAt": null,
    // //     "deletedAt": null,
    // //     "deleted": false,
    // //     "name": "Suporte",
    // //     "email": "suporte@umanni.com.br",
    // //     "role": "internal",
    // //     "photoUrl": "https://s3.amazonaws.com/umanni-email/personas/105.png",
    // //     "active": true
    // //   },
    // //   {
    // //     "id": "6RJFweR2VMTkpXRfZkphxsz51gK2",
    // //     "createdAt": new Date(),
    // //     "updatedAt": null,
    // //     "deletedAt": null,
    // //     "deleted": false,
    // //     "name": "Regiane Borges",
    // //     "email": "regiane.borges@umanni.com.br",
    // //     "role": "internal",
    // //     "photoUrl": "https://s3.amazonaws.com/umanni-email/personas/105.png",
    // //     "active": true
    // //   },
    // //   {
    // //     "id": 'xWDnO3qpGzXavSbnruZnEP5KgDk2',
    // //     "createdAt": new Date(),
    // //     "updatedAt": null,
    // //     "deletedAt": null,
    // //     "deleted": false,
    // //     "name": "Wander Rodrigues",
    // //     "role": "admin",
    // //     "email": "wander.rodrigues@umanni.com.br",
    // //     "photoUrl": "https://s3.amazonaws.com/umanni-email/personas/105.png",
    // //     "active": true
    // //   },
    // //   {
    // //     "id": "ElY00MCdyIZIu8VGES5lkWeYjhU2",
    // //     "createdAt": new Date(),
    // //     "updatedAt": null,
    // //     "deletedAt": null,
    // //     "deleted": false,
    // //     "name": "Amanda",
    // //     "email": "amanda.oliveira@umanni.com.br",
    // //     "role": "internal",
    // //     "photoUrl": "https://s3.amazonaws.com/umanni-email/personas/105.png",
    // //     "active": true
    // //   }
    // ]
    // const ORGANIZATION = {
    //   id: 'ambev-insights',
    //   name: 'Ambev',
    //   logoUrl: null,
    //   platforms: {
    //     android: {
    //       currentVersion: "3.0.0",
    //     },
    //     ios: {
    //       currentVersion: "3.0.0",
    //     },
    //     web: {
    //       currentVersion: "3.0.0",
    //     }
    //   },
    //   modules: {
    //     feedbacks: {
    //       active: true,
    //       settings: {
    //         anonymousFeedbacks: true,
    //         enableGroupFeedback: true,
    //         enableModerate: false,
    //         skillHasContexts: false,
    //         enableSanitization: true
    //       }
    //     },
    //     events: {
    //       active: true,
    //       settings: {
    
    //       }
    //     },
    //     messages: {
    //       active: true,
    //       settings: {
    //         moderation: false,
    //       }
    //     },
    //     moods: {
    //       active: true,
    //       settings: {
    //         blurOffensiveMaterial: true
    //       }
    //     },
    //     surveys: {
    //       active: true,
    //       settings: {
    //         multiple: true
    //       }
    //     },
    //     notifications: {
    //       active: true,
    //       settings: {
            
    //       }
    //     },
    //     tickets: {
    //       active: true,
    //       settings: {
    //       }
    //     }
    //   },
    //   settings: {
    //     users: {
    //       notifications: {
    //         engagement: {
    //           neverLoggedIn: {
    //             active: true,
    //             title: 'string',
    //             message: 'string',
    //             startAt: 5,
    //           },
    //           daysWithoutLoggingIn7: {
    //             active: true,
    //             title: 'string',
    //             message: 'string',
    //           },
    //           daysWithoutLoggingIn15: {
    //             active: true,
    //             title: 'string',
    //             message: 'string',
    //           },
    //           daysWithoutLoggingIn30: {
    //             active: true,
    //             title: 'string',
    //             message: 'string',
    //           }
    //         }
    //       },
    //       emails: {
    //         newUsers:{
    //           from: 'string',
    //           fromName: 'string',
    //           subject: 'string',
    //           message: 'string',
    //           iosStatus: false,
    //           iosUrl: 'string',
    //           androidStatus: false,
    //           androidUrl: 'string',
    //           webUrl: 'string',
    //           imageUrl: null
    //         }
    //       }
    //     },
    //     apparence: {
    //       theme: 'staging',
    //       colors: {
    //         primary: '#',
    //         accent: '#',
    //         warn: '#'
    //       }
    //     }
    //   }
    // }
    // this.firestore.organization().set(ORGANIZATION)
    // for(const user of USERS){
    //   this.firestore.users().doc(user.id).set(user)
    // }

    // this.firestore.organization().set(this.organization)
    // this.mock.forEach(user => {
    //   this.firestore.users().doc(user.id).set(user)
    // })
  }
}
