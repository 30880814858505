export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyCQUNAhPYA3oWhmaosHvIDsVBUufG_8b-k',
    authDomain: 'ambev-insights.firebaseapp.com',
    databaseURL: 'https://ambev-insights.firebaseio.com',
    projectId: 'ambev-insights',
    storageBucket: 'ambev-insights.appspot.com',
    messagingSenderId: '339319313089',
    appId: '1:339319313089:web:2fe782e7078e0069ac7a40',
    measurementId: 'G-0CR6WYLWBY'
  }
};
